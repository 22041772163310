import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AdminService } from "app/services/admin/admin.service";
import { EvaluationService } from "app/services/evaluation/evaluation.service";

@Component({
	selector: "objetivos-history",
	templateUrl: "./objetivos-history.component.html",
	styleUrls: ["./objetivos-history.component.scss"],
})
export class DialogObjetivosHistoricoComponent implements OnInit {
	personId;

	page = 0;
	pageSize = 5;
	total = 0;

	objetivos = [];

	user;
	person;
	permisosPDA;
	mainPeriod;

	nombre: "";
	perido_seleccionado: null;
	periodos = [];
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private translate: TranslateService,
		private evSvc: EvaluationService,
		private adminSvc: AdminService,
	) {
		this.personId = this.data.personId;
		this.user = this.data.user;
		this.person = this.data.person;
		this.permisosPDA = this.data.permisosPDA;
		this.mainPeriod = this.data.mainPeriod;
	}

	ngOnInit(): void {
		this.getPeriods();
		this.getPA();
	}

	getPA() {
		this.evSvc.getHistoricoObjetivos(this.personId, this.pageSize, this.page, { periodo: this.perido_seleccionado }).subscribe((data) => {
			this.objetivos = data.elements;
			this.total = data.total;
		});
	}

	getPeriods() {
		this.adminSvc.getAllPeriodosRevision(true).subscribe(
			(data) => {
				this.periodos = data.periodos;
			},
			(err) => {
				console.log(err);
			},
		);
	}

	filtrar() {
		this.page = 0;
		this.objetivos = [];
		this.total = 0;

		this.getPA();
	}

	pageChanged(event) {
		this.page = event.pageIndex;
		this.getPA();
	}

	onChangeElementValue(evt) {
		const _body = {
			id: evt.id,
			result: evt.value,
		};
		this.evSvc.saveActionPlanResult(_body).subscribe((data) => {
			console.log(data);
		});
		console.log(evt);
	}
}
