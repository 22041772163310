import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { TranslateService } from "@ngx-translate/core";
import { feedAnimations } from "app/animations";
import { AuthService } from "app/services/auth/auth.service";
import { EvaluationService } from "app/services/evaluation/evaluation.service";
import { ImagesService } from "app/services/helper/images.service";
import { PersonService } from "app/services/person/person.service";
import { ProjectService } from "app/services/project/project.service";
import { PuestosService } from "app/services/puesto/puestos.service";
import { UserService } from "app/services/user/user.service";
import { Persona, User } from "app/shared/models/user.interface";
import moment from "moment";
import { DialogInfoComponent } from "./dialog-info/dialog-info.component";
import { DialogObjetivosHistoricoComponent } from "./objetivos-history/objetivos-history.component";
import { PrivateFeedbackDialogComponent } from "./panel-control/private-feedback-dialog/private-feedback-dialog.component";

@Component({
	selector: "app-sied",
	templateUrl: "./sied.component.html",
	styleUrls: ["./sied.component.scss"],
	animations: [feedAnimations],
})
export class SiedComponent implements OnInit {
	project;

	// User container
	user: User;
	person: Persona;
	isEvaluator: boolean;
	isSelfPage: boolean = false;

	mainPeriod: any;
	previousPeriod: any;
	evalForm: any;
	puesto: any;
	evaluation: any;
	history: any;
	currentScore: any = "-";
	avgScore: any = "-";
	evaluationPanel: any;

	// Actions plans
	evPartial: any;
	actionPlans: any;
	filteredActionPlans: any;
	newActionPlanTitleInput: any;

	totalActionPending: any;
	totalActionDone: any;

	showOtrasMisiones: boolean;
	showHelpBoxActionPlan: boolean;

	// Feedback privado
	privateFeedbackRequestList: any;
	privateFeedbackResponseList: any;
	privateFeedbackHistory: any;

	// Cuestionario manager
	evaluatorsToEvaluate: any;
	evaluatorsForm;

	// Periodo revision PA
	paRevisionPeriod: any;

	// Filtros PA
	formFiltroPlanAccion: UntypedFormGroup;
	filterPAForm: {
		status: string;
		createdBy: {};
		inicio: { startDate: moment.Moment; endDate: moment.Moment };
		fin: { startDate: moment.Moment; endDate: moment.Moment };
	};
	ranges: {};
	localeDateRange: {};
	createdByUsers: Array<any> = [];
	@ViewChild("titleFilter") titleFilter: ElementRef;

	actionsPlansLoaded: boolean = false;

	showTeamButton: boolean = false;

	nextSelected: boolean = false;
	proyectoCargado = false;

	objetivosRevision = null;

	sumaDePesos = 0;
	constructor(
		private _formBuilder: UntypedFormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		public dialog: MatDialog,
		public userSvc: UserService,
		public imageSvc: ImagesService,
		public evaluationSvc: EvaluationService,
		public personSvc: PersonService,
		public projectSvc: ProjectService,
		public puestoSvc: PuestosService,
		private translate: TranslateService,
		private fuseMenuSvc: FuseNavigationService,
		private authSvc: AuthService,
	) {
		this.localeDateRange = {
			format: "YYYY-MM-DD",
			displayFormat: "DD/MM/YYYY",
			cancelLabel: "Cancelar",
			applyLabel: "Aplicar",
			clearLabel: "Limpiar",
			customRangeLabel: "Personalizar rango",
			firstDay: 1,
			daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
			monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
		};
	}
	permisos = { r: 0, w: 0, d: 0 };
	permisosPDA = { r: 0, w: 0, d: 0 };

	nextPDA = [];

	ngOnInit(): void {
		this.authSvc.getPermisosWeb("WEB-EVALUACIONES").subscribe((data) => {
			console.log("Permisos para esta pagina", data);
			if (data) {
				this.permisos.r = data.r;
				this.permisos.w = data.w;
				this.permisos.d = data.d;
			}
		});
		this.authSvc.getPermisosWeb("WEB-EVALUACIONES-PDA").subscribe((data) => {
			console.log("Permisos para esta pagina", data);
			if (data) {
				this.permisosPDA.r = data.r;
				this.permisosPDA.w = data.w;
				this.permisosPDA.d = data.d;
			}
		});
		this.fuseMenuSvc.updateNavigationItem("sied", { badge: null });
		this.showOtrasMisiones = false;
		this.showHelpBoxActionPlan = false;
		this.formFiltroPlanAccion = this._formBuilder.group({
			tipoPlanAccion: ["0"],
		});
		this.projectSvc.getProjectConfig().subscribe((data) => {
			this.project = data;
			if (this.project?.moduloObjetivos == 1) {
				this.checkObjetivesPeriodRevision();
			}
			this.proyectoCargado = true;
		});
		this.route.queryParams.subscribe((params) => {
			this.userSvc.getUser().subscribe((data: User) => {
				console.log("Params", params);
				this.user = data;
				//Si no hay parámetro de entrada, es el panel del propio usuario.
				const _personId = params.person ? params.person : this.user.id;
				this.personSvc.getPerson(_personId).subscribe((data) => {
					this.person = data;
					console.log("Person", data);
					// Comprobamos que el usuario tiene permisos para ver el perfil de esta persona (es evaluador o es el mismo)
					this.evaluationSvc.isEvaluator(this.person.id, "").subscribe((_isEvaluator) => {
						if (this.person.id === this.user.id) {
							this.isEvaluator = true;
							this.isSelfPage = true;
						} else if (_isEvaluator && _isEvaluator.length) {
							this.isEvaluator = true;
						} else {
							this.isEvaluator = false;
						}
						this.init();
					});
				});
			});
		});
		this.filterPAForm = {
			status: null,
			createdBy: null,
			inicio: null,
			fin: null,
		};
	}

	init() {
		const _now = moment().format("YYYY-MM-DD");
		// Buscamos la evaluación actual (periodo actual)
		this.evaluationSvc.getMainPeriod(_now).subscribe((data) => {
			this.mainPeriod = data;
			// Comprobamos si el periodo ha pasado, está activo o aun no se ha abierto
			this.processPeriodDate();
			console.log("MainPeriod", data);
			// Obtenemos el puesto sobre el que te vas a evaluar
			// Último puesto antes de que empezara el periodo de evaluación
			this.puestoSvc.getPuestoByPersonAndDate(this.person.id).subscribe((data) => {
				this.puesto = data;
				console.log("Puesto", data);
				// Obtenemos los fomularios disponibles
				this.evaluationSvc.getEvaluationForm(this.puesto.id).subscribe((data) => {
					this.evalForm = data;
					console.log("evalForm", data);
					// Obtenemos el último periodo de evaluacion del proyecto
					this.evaluationSvc.getPreviousPeriod(this.mainPeriod.startPeriod).subscribe((data) => {
						this.previousPeriod = data;
						console.log("Previous Period", data);
						if (this.mainPeriod.isActive) {
							if (this.previousPeriod) {
								//Si existe un periodo pasado
								this.evaluationSvc.getAllEvaluationPartialCurrent(this.previousPeriod.id, this.user.id).subscribe((data) => {
									if (data.length) {
										this.showTeamButton = true;
									}
								});
							} else {
								this.evaluationSvc.getAllEvaluationPartialCurrent(this.mainPeriod.id, this.user.id).subscribe((data) => {
									if (data.length) {
										this.showTeamButton = true;
									}
								});
							}
						} else {
							this.evaluationSvc.getAllEvaluationPartialCurrent(this.mainPeriod.id, this.user.id).subscribe((data) => {
								if (data.length) {
									this.showTeamButton = true;
								}
							});
						}
						// Nota del periodo FIXME
						if (this.evalForm) {
							if (this.mainPeriod.isActive) {
								this.evaluationSvc.getEvaluation(this.evalForm.id, this.person.id, this.mainPeriod.id, "0").subscribe((data) => {
									this.evaluation = data;
									this.currentScore = this.evaluation ? this.evaluation.totalResult.toFixed(2) : "-";
								});
							} else if (!this.mainPeriod.isLast && this.previousPeriod) {
								this.evaluationSvc.getEvaluation(this.evalForm.id, this.person.id, this.previousPeriod.id, "0").subscribe((data) => {
									this.evaluation = data;
									this.currentScore = this.evaluation ? this.evaluation.totalResult.toFixed(2) : "-";
								});
							} else {
								this.evaluationSvc.getEvaluation(this.evalForm.id, this.person.id, this.mainPeriod.id, "0").subscribe((data) => {
									this.evaluation = data;
									this.currentScore = this.evaluation ? this.evaluation.totalResult.toFixed(2) : "-";
								});
							}
						}
						// Nota media
						if (this.mainPeriod.isLast) {
							this.evaluationSvc.getAvgEvaluations(this.person.id, _now).subscribe((data) => {
								this.avgScore = data.result ? data.result.toFixed(2) : "-";
							});
						} else if (this.previousPeriod) {
							this.evaluationSvc.getAvgEvaluations(this.person.id, this.previousPeriod.endPeriod).subscribe((data) => {
								this.avgScore = data.result ? data.result.toFixed(2) : "-";
							});
						}
						// Panel de evaluaciones
						this.evaluationSvc.getPanelEvaluations(this.person.id, this.mainPeriod.id).subscribe((data) => {
							this.evaluationPanel = data;
							// BlueSpace
							if (this.projectSvc.getProjectId() === "94") {
								this.evaluationPanel.totalEvaluations > 1 ? this.evaluationPanel.totalEvaluations-- : null;
								this.evaluationPanel.totalPendings > 1 ? this.evaluationPanel.totalPendings-- : null;
							}
							if (!this.mainPeriod.isActive /**&& !this.mainPeriod.isEnabledSaveEvaluationBeforePeriod*/) {
								this.evaluationPanel.totalPendings = 0;
							}
						});

						// Obtenemos los evaluadores
						this.evaluationSvc.getEvaluators(this.person.id, this.puesto.id, this.mainPeriod.id).subscribe((data) => {
							this.evaluatorsToEvaluate = data;
							// this.evaluatorsToEvaluate.push(this.user);
						});
						// Obtenemos la evaluacion parcial de la persona consultada
						this.evaluationSvc.getEvaluationPartial(this.person.id, this.user.id, this.mainPeriod.id).subscribe((data) => {
							this.evPartial = data;
							console.log("Evaluation Partial", data);
						});

						// Obtenemos los planes de acción de la persona consultada
						this.getActionsPlans();
						// Historico de evaluaciones
						this.loadHistoryTab(0);
						if (this.mainPeriod.isEnabledPrivateFeedback) {
							// Feedback privados solicitados
							this.evaluationSvc.getAllPrivateFeedbackRequestByUserAndPeriod(this.mainPeriod.id).subscribe((data) => {
								if (data.length) {
									this.privateFeedbackRequestList = data;
								}
								console.log("Feedback privado solicitado", this.privateFeedbackRequestList);
							});
							// Peticiones feedback privado
							this.evaluationSvc.getAllPrivateFeedbackResponseByUserAndPeriod(this.mainPeriod.id).subscribe((data) => {
								if (data.length) {
									this.privateFeedbackResponseList = data;
								}
								console.log("Peticion feedback privado", this.privateFeedbackResponseList);
							});
						}
						// Historico feedback privado
						this.evaluationSvc.getPrivateFeedbackHistoryByUser().subscribe((data) => {
							if (data.length) {
								this.privateFeedbackHistory = data;
							}
							console.log("Historico feedback privado", this.privateFeedbackHistory);
						});
					});
				});
			});
			// Evaluators form
			if (this.mainPeriod.isEnabledEvaluationEvaluators) {
				this.evaluationSvc.getEvaluatorsForm().subscribe((data) => {
					this.evaluatorsForm = data;
				});
			}
			// Periodo de revisión activo
			if (this.mainPeriod.periodRevision) {
				this.paRevisionPeriod = this.mainPeriod.periodRevision.find((pr) => {
					const _now = moment();
					const _startDate = moment(pr.startPeriod);
					const _endDate = moment(pr.endPeriod);
					if (_now.isBetween(_startDate, _endDate)) {
						return pr;
					}
				});
				console.log("Periodo de revisión activo", this.paRevisionPeriod);
			}
		});
	}

	processPeriodDate() {
		if (!this.mainPeriod) {
			return;
		}
		const _startDate = moment(this.mainPeriod.startPeriod);
		const _endDate = moment(this.mainPeriod.endPeriod);
		const _now = moment();

		if (_now > _endDate) {
			this.mainPeriod.isActive = false;
			this.mainPeriod.isLast = true;
		} else if (_now.isBetween(_startDate, _endDate) || this.mainPeriod.isEnabledSaveEvaluationBeforePeriod) {
			this.mainPeriod.isActive = true;
			this.mainPeriod.isLast = false;
		} else {
			this.mainPeriod.isActive = false;
			this.mainPeriod.isLast = false;
		}
	}

	formatDate(timestamp: number) {
		return moment(timestamp).format("DD/MM/YYYY");
	}

	formatDateString(timestamp: number) {
		return moment(timestamp).format("MMMM YYYY").toUpperCase();
	}

	loadHistoryTab(index) {
		const formType = index === 0 ? "EVALUATION" : "EVALUATION_EVALUATORS";
		this.evaluationSvc.getAllEvaluationsByPerson(this.person.id, formType).subscribe((data) => {
			this.history = data;
			console.log("History", data);
		});
	}

	getActionsPlans(newId?) {
		this.evaluationSvc.getAllActionsPlans(this.person.id).subscribe((data) => {
			if (newId && this.project?.moduloObjetivos != 1) {
				data.find((ap) => ap.id == newId).isNew = true;
			}
			this.actionPlans = data;
			this.filteredActionPlans = this.actionPlans;
			this.calculateCompleted();
			console.log("Action Plans", data);
			this.actionsPlansLoaded = true;
		});

		if (this.project?.id) {
			if (this.project?.moduloObjetivos == 1) {
				this.evaluationSvc.getNextActionPlansBlue(this.person.id).subscribe((data) => {
					if (newId) {
						data.find((ap) => ap.id == newId).isNew = true;
					}
					this.nextPDA = data;
				});
			}
		}
	}

	calculateCompleted() {
		this.totalActionPending = this.actionPlans.length;
		this.totalActionDone = 0;
		this.actionPlans.forEach((ap) => {
			// Calcular completados
			if (ap.userResult === "100") {
				this.totalActionPending--;
				this.totalActionDone++;
			}
			// Usuarios que han creado PA
			const creationUserId = this.createdByUsers.find((user) => user.id === ap.creationUser_id);
			if (!creationUserId) {
				this.createdByUsers.push({ id: ap.creationUser_id, name: ap.creationUser_name });
			}
		});
	}

	onActionPlanTitle(evt) {
		this.newActionPlanTitleInput = evt.target;
	}

	addPA() {
		const _newActionPlanTitle = this.newActionPlanTitleInput.value;
		this.evaluationSvc
			.saveActionPlan({
				id: null,
				title: _newActionPlanTitle,
				puesto_id: this.puesto.id,
				person_id: this.person.id,
				evaluationPartial_id: this.evPartial ? this.evPartial.id : null,
				period: this.mainPeriod.id,
			})
			.subscribe((data) => {
				if (data.success) {
					this.newActionPlanTitleInput.value = "";
					this.getActionsPlans(data.id);
				}
			});
	}

	addPABlue() {
		const _newActionPlanTitle = this.newActionPlanTitleInput.value;
		this.evaluationSvc
			.saveActionPlan({
				id: null,
				title: _newActionPlanTitle,
				puesto_id: this.puesto.id,
				person_id: this.person.id,
				evaluationPartial_id: this.evPartial ? this.evPartial.id : null,
				period: this.mainPeriod.id,
				isObjetivo: 1,
			})
			.subscribe((data) => {
				if (data.success) {
					this.newActionPlanTitleInput.value = "";
					this.getActionsPlans(data.id);
				}
			});
	}

	filterPATitle(filter) {
		if (!filter.value) {
			this.filteredActionPlans = this.actionPlans;
		} else {
			this.filteredActionPlans = this.actionPlans.filter((ap) => ap.title.includes(filter.value));
		}
	}

	filterPA() {
		let filteredPA = [];
		// Filtrar por estado
		if (this.filterPAForm.status) {
			const filtered = this.actionPlans.filter((ap) => ap.userResult == this.filterPAForm.status);
			filteredPA = filtered;
		} else {
			filteredPA = this.actionPlans;
		}
		// Filtrar por creador
		if (this.filterPAForm.createdBy) {
			const filtered = filteredPA.filter((ap) => ap.creationUser_id == this.filterPAForm.createdBy);
			filteredPA = filtered;
		}
		// Filtrar por fecha inicio
		if (this.filterPAForm.inicio.startDate) {
			let filtered = [];
			filteredPA.forEach((ap) => {
				const startDate = moment(ap.startDate).startOf("day");
				if (startDate.isBetween(this.filterPAForm.inicio.startDate.startOf("day"), this.filterPAForm.inicio.endDate.endOf("day"), null, "[]")) {
					filtered.push(ap);
				}
			});
			filteredPA = filtered;
		}
		// Filtrar por fecha fin
		if (this.filterPAForm.fin.startDate) {
			let filtered = [];
			filteredPA.forEach((ap) => {
				const endDate = moment(ap.endDate).startOf("day");
				if (endDate.isBetween(this.filterPAForm.fin.startDate.startOf("day"), this.filterPAForm.fin.endDate.endOf("day"), null, "[]")) {
					filtered.push(ap);
				}
			});
			filteredPA = filtered;
		}
		this.titleFilter.nativeElement.value = "";
		this.filteredActionPlans = filteredPA;
	}

	clearFilterPA() {
		this.filteredActionPlans = this.actionPlans;
		this.titleFilter.nativeElement.value = "";
		this.filterPAForm = {
			status: null,
			createdBy: null,
			inicio: null,
			fin: null,
		};
	}

	toogleOtrasMisiones() {
		this.showOtrasMisiones = !this.showOtrasMisiones;
	}

	iconEstadoMision(estado: string) {
		let nameIcon = "";

		if (estado === "success") {
			nameIcon = "check";
		} else if (estado === "danger") {
			nameIcon = "close";
		} else if (estado === "warning") {
			nameIcon = "compare_arrows"; //drag_handle
		}

		return nameIcon;
	}

	openDialogInfo(tipo: string): void {
		const dialogRef = this.dialog.open(DialogInfoComponent, {
			data: { tipoInfo: tipo },
			panelClass: "panel-feedbalia",
			autoFocus: false,
			maxWidth: "40vw",
			position: {
				top: "10rem",
			},
		});
	}

	getImagePerson(nickname: string) {
		return this.imageSvc.getImageThumb(nickname);
	}

	openPanelControl(targetTab: number) {
		const _params = {
			targetIndex: targetTab,
		};
		this.router.navigate(["/sied/control-panel"], { queryParams: _params });
	}

	goToEvaluationForm(itemEval) {
		const _params = {
			formId: itemEval.formId,
			personId: itemEval.personId,
			periodId: itemEval.periodId,
		};
		this.router.navigate(["/evaluacion"], { queryParams: _params });
	}

	openEvaluation(target) {
		if (target === "last") {
			this.goToEvaluationForm(this.history[0]);
		} else if (target === "main") {
			this.evaluationSvc.getEvaluationForm(this.puesto.id).subscribe((data) => {
				this.goToEvaluationForm({
					formId: data.id,
					personId: this.person.id,
					periodId: this.mainPeriod.id,
				});
			});
		}
	}

	openFeedbackDialog(periodName, privateFeedback, isRequest) {
		const dialogRef = this.dialog.open(PrivateFeedbackDialogComponent, {
			data: {
				periodName,
				isRequest,
				privateFeedback,
				close: () => {
					dialogRef.close();
				},
			},
			panelClass: "panel-feedbalia",
			autoFocus: false,
			maxWidth: "50vw",
			minWidth: "40vw",
		});
	}

	openHistorioObjetivos() {
		const dialogRef = this.dialog.open(DialogObjetivosHistoricoComponent, {
			data: {
				personId: this.person.id,
				user: this.user,
				person: this.person,
				permisosPDA: this.permisosPDA,
				mainPeriod: this.mainPeriod,
				close: () => {
					dialogRef.close();
				},
			},
			panelClass: "panel-feedbalia",
			autoFocus: false,
			maxWidth: "60vw",
			minWidth: "60vw",
		});
	}

	tooglePeriod(period) {
		period.extended = !period.extended;
	}

	historyTabChanged(evt) {
		this.loadHistoryTab(evt.index);
	}

	goToManagerEvaluation(evaluator) {
		const _params = {
			formId: this.evaluatorsForm.id,
			personId: evaluator.id,
			periodId: this.mainPeriod.id,
		};
		this.router.navigate(["/evaluacion"], { queryParams: _params });
	}

	setCheckByUser(isChecked) {
		const _body = {
			evaluationId: this.evaluation.id,
			isChecked,
		};
		this.evaluationSvc.setEvaluationCheckedByUser(_body).subscribe((data) => {
			if (data.success) {
				this.evaluation.checkByUser = data.value;
			}
		});
	}

	onChangeElementValue(evt) {
		const _body = {
			id: evt.id,
			result: evt.value,
		};
		this.evaluationSvc.saveActionPlanResult(_body).subscribe((data) => {
			console.log(data);
		});
		console.log(evt);
	}

	// En el proyecto de Syrsa, solo pueden crear y editar los PDA los managers.
	canCreatePlanAction() {
		if (this.project?.id == 453) {
			return !this.isSelfPage && this.isEvaluator ? true : false;
		} else {
			return true;
		}
	}

	canEditPlanAction(pda) {
		// En Exxita, solo se pueden editar los planes de acción creados después del periodo actual.
		if (this.project?.name == "cyg") {
			return this.mainPeriod && this.mainPeriod.isActive ? false : true;
		}
		// En el proyecto de Syrsa, solo pueden crear y editar los PDA los managers.
		else if (this.project?.name == "syrsa") {
			return !this.isSelfPage && this.isEvaluator ? true : false;
		} else if (this.project?.name == "leroymerlin") {
			if (pda.fixed) {
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	}

	goToColaboradoresActuales() {
		this.router.navigate(["/sied/colaboradores-actuales"]);
	}

	canBlockPDAs() {
		if (this.project?.name == "leroymerlin") {
			return true;
		} else {
			return false;
		}
	}

	setNextSelected(valor) {
		this.nextSelected = valor;
		this.evaluationSvc.getNextActionPlansBlue(this.person.id).subscribe((data) => {
			this.nextPDA = data;
		});
	}
	checkNextPesos() {
		let pesos = 0;

		for (let elem of this.nextPDA) {
			if (Number(elem.peso)) pesos += Number(elem.peso);
		}

		this.sumaDePesos = pesos;

		return pesos <= 100.001 && pesos >= 99.999;
	}

	checkObjetivesPeriodRevision() {
		this.evaluationSvc.getObjetivosPeriodRevision().subscribe((data) => {
			if (data?.activo) {
				this.objetivosRevision = data.periodo;
			}
		});
	}
}
