<h2 mat-dialog-title>Histórico de objetivos</h2>
<mat-dialog-content class="mat-typography">
	<div class="w-100-p" fxLayout="row">
		<div fxLayout="column" fxFlex="3 1 0" fxLayoutAlign="end center" class="header" style="padding: 8px">
			<label style="text-align: left; width: 100%" for="areaFilter">Filtrar por periodo </label>
			<mat-select class="input-form" [(value)]="perido_seleccionado" multiple placeholder="Seleccione el periodo">
				<mat-option *ngFor="let puesto of periodos" [value]="puesto.id">{{ puesto.name }}</mat-option>
			</mat-select>
		</div>
		<div fxLayout="column" fxFlex="1 1 0" fxLayoutAlign="end center" style="padding: 8px">
			<button mat-raised-button class="btn-feedbalia w-100-p" (click)="filtrar()">Filtrar</button>
		</div>
	</div>

	<div class="w-100-p" fxLayout="row" fxLayoutAlign="center center" style="margin-top: 16px">
		<div class="w-100-p" fxLayout="column" fxLayoutAlign="center center">
			<div *ngFor="let itemPA of objetivos" style="width: 70%">
				<action-plan-blue
					[datos-plan-accion]="itemPA"
					[revisionPeriodEnabled]="false"
					[evpartial-id]="null"
					[period-id]="null"
					[user]="user"
					[person]="person"
					[fullEdit]="itemPA.canEdit && user.id != person.id"
					[fullDuplicar]="false"
					[editable]="itemPA.canEdit && user.id != person.id"
					[read-only]="!(itemPA.canEdit && user.id != person.id)"
					[startPeriod]="mainPeriod.startPeriod"
					[inEvaluation]="false"
					[canBeBlocked]="false"
					[canComment]="true"
					[permisos]="permisosPDA"
					(reload)="getPA()"
					(onChangeValue)="onChangeElementValue($event)"
				>
				</action-plan-blue>
			</div>
			<div style="width: 70%" *ngIf="objetivos.length == 0">
				<span style="font-style: italic"> No existen objetivos en el histórico</span>
			</div>

			<div class="w-100-p" fxLayout="column" fxLayoutAlign="center center">
				<mat-paginator
					[hidePageSize]="true"
					[length]="total"
					[pageIndex]="0"
					[pageSize]="5"
					(page)="pageChanged($event)"
					showFirstLastButtons
				></mat-paginator>
			</div>
		</div>
	</div>
</mat-dialog-content>
