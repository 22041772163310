@import "/src/@fuse/scss/fuse";

#panel-control {
	.card-fullwidth {
		max-width: 100%;
		width: 100%;
		/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
		.mat-tab-label-content {
			color: #277dab;
			font-weight: bold;
		}
	}

	.card-border-top {
		border-top-color: var(--feedbalia-color-primary-500) !important;
	}

	.notification-container {
		padding: 36px 36px 0 36px !important;
	}

	.filter-container {
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
		.statics-filters {
			& > mat-form-field {
				margin-right: 8px !important;
			}
		}
		.button {
			& > button {
				width: 200px;
			}
		}
		mat-label {
			background: white;
			font-size: 10px;
			margin-left: 5px;
		}
	}

	.cards-container {
		.card-colaborador {
			border-top-color: var(--feedbalia-color-primary-500) !important;
			width: 320px;
			padding: 2rem;
			margin: 2rem;
		}
		.card-auto {
			border-top-color: var(--feedbalia-color-primary-500) !important;
			//	background-color: rgba(245, 245, 245, 0);
			width: 320px;
			padding: 2rem;
			margin: 2rem;
			.icon-badge {
				position: absolute;
				align-self: flex-end;
				padding-right: 0px;
				margin-right: 0px;
				padding-top: 0px;
				//transform: rotate(270deg);
				transform: scale(2.5);
				color: rgba(230, 12, 12, 0.726);
			}
		}
	}

	.action-button {
		padding: 8px;
		min-width: 120px;
		border-radius: 3px;
		background: var(--feedbalia-color-primary-500);
		color: white;
		font-weight: bold;
		border: none;
	}

	.pointer {
		&:hover {
			cursor: pointer;
		}
	}

	.mat-mdc-form-field[dense] {
		/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
		.mat-form-field-flex > .mat-form-field-infix {
			padding: 0px !important;
			border-top: 0px !important;
		}
		/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
		.mat-form-field-label {
			top: 5px;
			font-weight: bold;
		}
		/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
		.mat-form-field-label-wrapper {
			top: -1.5em;
		}
		/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
		&.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
			transform: translateY(-1.1em) scale(0.75);
		}
		/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
		.mat-form-field-wrapper {
			padding-bottom: 0;
		}
	}
}
